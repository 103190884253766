import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PostCard from "../components/PostCard"

class CategoryIndexTemplate extends React.Component {
  render() {
    const props = this.props
    const category = this.props.pageContext.category
    const posts = this.props.data.allMarkdownRemark.edges
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout title={siteTitle}>
        <Seo
          // title={`#${category}`}
          title={`#${category.charAt(0).toUpperCase() + category.slice(1)}`}
          keywords={[`${category}`, `blog`, `gatsby`, `javascript`, `react`]}
        />
        <header className="tag-page-head">
          <h1 className="page-head-title">#{category}({props.data.allMarkdownRemark.totalCount})</h1>
        </header>
      <div className="post-feed">
        {posts.map(({ node }) => {
          return (
            <PostCard
              key={node.fields.slug}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
    </Layout>
    )
  }
}

export default CategoryIndexTemplate;

export const pageQuery = graphql`
  query PostByCategory($category: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "blog" }, categories: { in: [$category] } } }, sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
            categories
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 670, maxHeight: 200, cropFocus: CENTER) {
                  src
                  originalName
                }
              }
            }
          }
        }
      }
    }
  }
`
